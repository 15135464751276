import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import LinkWithQuery from '../../LinkWithQuery';

export default function FeaturedArticles() {
	return (
		<StaticQuery
			query={graphql`
				query FeaturedArticlesQuery {
					allMdx(filter: { frontmatter: { featured: { eq: true } } }, limit: 3) {
						nodes {
							frontmatter {
								image
								category
								slug
								articleCardTitle
								articleCardDescription
							}
						}
					}
				}
			`}
			render={({ allMdx: { nodes } }) => {
				return nodes.map((post, i) => (
					<div className="o-row-2 o-row-3-d" key={i}>
						<article className="c-resource-featured-article">
							<div
								className="c-resource-featured-article__img"
								style={{ backgroundImage: `url(${post.frontmatter.image})` }}
							/>
							<div className="c-resource-featured-article__body">
								<div className="o-row-2">
									<h3 className="c-resource-featured-article__title">{post.frontmatter.articleCardTitle}</h3>
								</div>
								<div className="o-row-2">
									<p className="c-resource-featured-article__copy">{post.frontmatter.articleCardDescription}...</p>
								</div>
								<LinkWithQuery
									to={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
									className="c-resource__cta"
								>
									Read More
								</LinkWithQuery>
							</div>
						</article>
					</div>
				));
			}}
		/>
	);
}
