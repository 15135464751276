import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';
import LinkWithQuery from '../../components/LinkWithQuery';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import FeaturedArticles from '../../components/resources/FeaturedArticles';
import ArticleCard from '../../components/resources/ArticleCard';
import HrefLangManager from '../../components/HrefLangManager';

const Resources = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const {
		allMdx: { nodes }
	} = useStaticQuery(graphql`
		query ResourcesIndexQuery {
			allMdx(
				limit: 6
				filter: {
					frontmatter: { featured: { ne: true } }
					internal: { contentFilePath: { regex: "/resources-post/" } }
				}
			) {
				nodes {
					frontmatter {
						category
						slug
						articleCardTitle
						articleCardDescription
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<Seo
				title="Resource Center | hear.com"
				description="hear.com has gathered this resource center for you to learn everything you need to know about hearing aids and hearing loss. "
			/>
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/resources/'],
					['en', 'https://www.hear.com/resources/'],
					['en-US', 'https://www.hear.com/resources/'],
					['en-CA', 'https://ca.hear.com/resources/']
				]}
			/>
			<div className="o-wrapper">
				<div className="o-row-2p5">
					<h1 className="c-resource-title">Resource Center</h1>
				</div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-2">
					<p className="c-resource-copy">
						Hearing loss affects 48 million people in the United States, and that number continues to grow each day. But
						thanks to the latest innovations in hearing aid technology, over 10,000 people a day are treating their
						problem with new hearing aids. As one of the fastest-growing hearing care companies in the world, our goal
						is to educate and empower you with the knowledge you need to live your best life with the gift of better
						hearing. This resource center offers the most up-to-date information from the leading experts, hearing
						specialists and audiologists in the industry.
					</p>
				</div>
				<div className="o-row-4">
					<div className="c-resource-categories">
						<LinkWithQuery
							to="/resources/hearing-loss/"
							className="c-resource-categories__link c-resource-categories__link--1"
						>
							<span className="c-resource-categories__name">Hearing Loss</span>
						</LinkWithQuery>
						<LinkWithQuery
							to="/resources/hearing-aids/"
							className="c-resource-categories__link c-resource-categories__link--2"
						>
							<span className="c-resource-categories__name">Hearing Aids</span>
						</LinkWithQuery>
						<LinkWithQuery
							to="/resources/hearing-aid-success/"
							className="c-resource-categories__link c-resource-categories__link--3"
						>
							<span className="c-resource-categories__name">Hearing Aid Success</span>
						</LinkWithQuery>
						<LinkWithQuery
							to="/resources/videos/"
							className="c-resource-categories__link c-resource-categories__link--4"
						>
							<span className="c-resource-categories__name">Videos</span>
						</LinkWithQuery>
					</div>
				</div>
				<div className="o-row-4">
					<div className="c-resource-divider"></div>
				</div>
				<div className="o-row-4">
					<h2 className="c-resource-sub-title">Featured Articles</h2>
				</div>
				<FeaturedArticles />
				<div className="o-row-2 o-row-3-p">
					<div className="c-resource-cards-wrapper">
						{nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
								title={post.frontmatter.articleCardTitle}
								description={post.frontmatter.articleCardDescription}
							/>
						))}
					</div>
				</div>
				<div className="o-row-4">
					<LinkWithQuery
						to="/resources/all-articles/"
						className="c-resource__cta"
						style={{ margin: '0 auto', display: 'block', width: '160px', textAlign: 'center' }}
					>
						All Articles
					</LinkWithQuery>
				</div>
			</div>
		</Layout>
	);
};

export default Resources;
